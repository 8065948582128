<template>
  <section>
    <v-container class="contain">
      <v-row>
        <v-col md="12">
          <div>
            <h4>
              Filter For Property Data
              <v-icon v-if="!filtersection" @click="toggleFilter">
                mdi-chevron-down </v-icon
              ><v-icon v-if="filtersection" @click="toggleFilter">
                mdi-chevron-up
              </v-icon>
            </h4>
          </div>

          <div>
            <v-switch
              ref="wishlisthBtn"
              class="wishlistBtn"
              :class="{ stickyBtn: stickWishlist }"
              v-model="wishlist"
              :label="`Favourites`"
              @change="filterData"
            ></v-switch>
            <v-form v-if="filtersection">
              <v-row>
                <v-col md="12" class="pt-5"
                  ><strong> Search Criteria </strong>
                </v-col>
                <v-col md="4" class="no-pad-tb">
                  <v-label>Search property with keywords </v-label>
                  <v-text-field
                    class="pt-2"
                    v-model="area"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4" class="no-pad-tb">
                  <v-label>'NW', 'NW3'</v-label>
                  <v-text-field
                    class="pt-2"
                    v-model="prefixpostcode"
                    label="Postcode"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4" class="no-pad-tb">
                  <v-label>Property Type</v-label>
                  <v-select
                    class="pt-2"
                    :items="propertyTypes"
                    v-model="propertyType"
                    outlined
                    multiple
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pt-0"
                  ><strong> Number Filter </strong>
                </v-col>
                <v-col md="3" class="no-pad-tb">
                  <v-label>Min Price</v-label>
                  <v-text-field
                    class="pt-2"
                    v-model="minprice"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col md="3" class="no-pad-tb">
                  <v-label>Max Price</v-label>
                  <v-text-field
                    class="pt-2"
                    v-model="maxprice"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" class="no-pad-tb">
                  <v-label>Min Bed</v-label>
                  <v-text-field
                    class="pt-2"
                    v-model="minbed"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" class="no-pad-tb">
                  <v-label>Max Bed</v-label>
                  <v-text-field
                    class="pt-2"
                    v-model="maxbed"
                    label="Max Bed"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="pt-0"
                  ><strong> Flag Filter </strong>
                </v-col>
                <v-col md="3" class="no-pad-tb">
                  <v-label>Is Listing Live</v-label>
                  <v-select
                    class="pt-2"
                    :items="isListingLives"
                    v-model="isListing"
                    item-text="text"
                    item-value="val"
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col md="3" class="no-pad-tb label100">
                  <v-label
                    >Postcode
                    <div
                      class="d-inline-flex justify-space-between btnActReset"
                    >
                      <span class="resetBtn" @click="resetPostcode"
                        >Default</span
                      >
                      <span class="resetBtn" @click="clearPostcode"
                        >Clear All</span
                      >
                    </div>
                  </v-label>
                  <v-select
                    :items="postcodes"
                    v-model="postcodesDefaults"
                    outlined
                    item-text="postcode"
                    item-value="postcode"
                    class="pt-2"
                    multiple
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col md="3" class="no-pad-tb">
                  <v-label>Saved Searches</v-label>
                  <v-select
                    :items="searches"
                    v-model="search"
                    outlined
                    item-text="searchName"
                    item-value="searchName"
                    @change="getSeachSingle"
                    class="pt-2"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col md="3" class="no-pad-tb">
                  <v-label>Listed Date</v-label>
                  <v-select
                    :items="dayAdded"
                    v-model="dayAddedSelected"
                    outlined
                    item-text="title"
                    item-value="day"
                    @change="dateFixer"
                    class="pt-2"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
            <v-col md="12" class="mr-btm d-flex justify-space-between">
              <div>
                <v-btn
                  v-if="!sendEmailLoading"
                  @click="sendDataToEmail"
                  class="leftSec"
                  >Send To Email</v-btn
                >
                <v-btn v-if="sendEmailLoading" class="leftSec"
                  >Processing ...</v-btn
                >
                <v-btn class="ml-5 leftSec" @click="searchPopup"
                  >Save Search</v-btn
                >
              </div>
              <v-btn v-if="filterLoading" loading class="formInside"></v-btn>
              <v-btn class="formInside" @click="filterData">Apply Filter</v-btn>
            </v-col>
          </div>
        </v-col>

        <v-col md="12" v-if="propertyLoading">
          <Loading />
        </v-col>

        <v-col md="12" v-if="!propertyLoading">
          <section>
            <v-row>
              <v-col md="12">
                <h3 class="table-heading flexBox-space">
                  <span> Rental Listing </span>

                  <span v-if="!propertyLoading" class="d-flex">
                    Total Properties :
                    {{ pagination.total+15303 }}
                    <div
                      class="d-flex justify-space-between align-center ml-5"
                      v-if="properties.length != 0"
                    >
                      <v-btn @click="prevPage(pagination.previous)">
                        <v-icon> mdi-chevron-left </v-icon>
                        Previous
                      </v-btn>
                      <strong class="pl-3 pr-1" v-if="!filterLoading">
                        {{ limit * (pagination.next - 2) + 1 }}
                        -
                        {{ (pagination.next - 1) * limit }}
                      </strong>
                      <strong class="pl-3 pr-1" v-if="filterLoading">
                        <v-progress-circular
                          indeterminate
                          size="25"
                        ></v-progress-circular>
                      </strong>
                      <v-btn class="ml-3" @click="nextPage(pagination.next)">
                        Next
                        <v-icon> mdi-chevron-right </v-icon>
                      </v-btn>
                    </div>
                  </span>
                </h3>
                <v-simple-table class="tableborder tableBox">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th width="100">Address</th>
                      <th>Property Link</th>
                      <th class="relRow">Monthy Rent</th>
                      <th class="relRow">Bedrooms</th>
                      <th class="relRow">Postcode</th>
                      <th>Listed Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="filterLoading">
                    <tr>
                      <td class="text-center" colspan="13">
                        <v-progress-circular
                          indeterminate
                        ></v-progress-circular>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="!filterLoading">
                    <tr v-if="properties.length == 0">
                      <td colspan="14">No Property Found</td>
                    </tr>
                    <tr
                      v-for="(data, index) in allRentProperties.results"
                      :key="index"
                      :class="{ activeTab: indexSeletecd == index }"
                    >
                      <td>{{ index + 1 }}</td>

                      <td width="250">
                        {{ data.propertyAddress }}
                      </td>
                      <td>
                        <a
                          @click="makeItActive(index)"
                          target="_blank"
                          :href="`https://www.rightmove.co.uk/properties/${data.propertyId}`"
                          >View Listing</a
                        >
                      </td>
                      <td>₤{{ numberFormat(data.price) }}</td>
                      <td>{{ data.bedrooms }}</td>
                      <td>{{ data.postcode }}</td>
                      <td
                        v-if="data.listedDate != undefined"
                        :class="{
                          dateHighlight:
                            getDateTime(data.listedDate) < 14 ||
                            data.listedDate == 'Added today',
                        }"
                      >
                        {{ data.listedDate }}
                      </td>
                      <td v-if="data.listedDate == undefined"></td>

                      <td>
                        <div class="d-flex flex-column align-center">
                          <v-icon
                            class="editIcon favourite"
                            v-if="data.wishlist"
                            @click="wishlistAct(data)"
                          >
                            mdi-heart
                          </v-icon>
                          <v-icon
                            class="editIcon favouriteO"
                            v-if="!data.wishlist"
                            @click="wishlistAct(data)"
                          >
                            mdi-heart-outline
                          </v-icon>
                          <a
                            :href="`/dashboard/singleRentProperty/${data._id}`"
                            target="_blank"
                          >
                            <v-icon class="editIcon"> mdi-eye </v-icon>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="properties.length != 0">
                    <tr>
                      <td colspan="2">
                        <strong>
                          <span v-if="!propertyLoading">
                            Total Properties :
                            {{ pagination.total+15303 }}</span
                          >
                          <span v-if="propertyLoading">
                            Total Properties :
                            <v-progress-circular
                              indeterminate
                              size="25"
                            ></v-progress-circular
                          ></span>
                        </strong>
                      </td>
                      <td colspan="3"></td>
                      <td class="iconSection" colspan="3">
                        <div v-if="propertyLoading">
                          <v-progress-circular
                            indeterminate
                            size="25"
                          ></v-progress-circular>
                        </div>
                        <div
                          class="d-flex flexend align-center"
                          v-if="!propertyLoading"
                        >
                          <v-btn @click="prevPage(pagination.previous)">
                            <v-icon> mdi-chevron-left </v-icon>
                            Previous
                          </v-btn>
                          <strong class="pl-3 pr-1" v-if="!filterLoading">
                            {{ limit * (pagination.next - 2) + 1 }}
                            -
                            {{ (pagination.next - 1) * limit }}
                          </strong>
                          <strong class="pl-3 pr-1" v-if="filterLoading">
                            <v-progress-circular
                              indeterminate
                              size="25"
                            ></v-progress-circular>
                          </strong>
                          <v-btn @click="nextPage(pagination.next)">
                            Next
                            <v-icon> mdi-chevron-right </v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </v-simple-table>
                <center class="py-5" v-if="loadMore">
                  <v-progress-circular indeterminate></v-progress-circular>
                </center>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <div class="fixPop" v-if="popSearch">
        <div class="formBox">
          <h1 class="mb-2">Search Name</h1>
          <div class="pb-2">
            <small><i>Please enter the search name</i></small>
          </div>
          <v-text-field
            outlined
            v-model="searchName"
            placeholder="Search Name"
          ></v-text-field>
          <v-btn @click="creatSearch" :disabled="searchName == ''">Save</v-btn>
          <v-btn @click="searchClosePopup" class="ml-5">Close</v-btn>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";
import moment from "moment";
import moment_timezone from "moment-timezone";
export default {
  name: "RentProperties",
  computed: {
    ...mapGetters([
      "allPostCodes",
      "allPostCodePrefix",
      "allPrefixPostCode",
      "allRightmoveDownload",
      "allLoadingDownload",
      "allRentProperties",
      "allRegion",
      "allPropertyReport",
      "allSearches",
    ]),
  },
  components: {
    Loading,
  },

  data() {
    return {
      stickWishlist: false,
      loadingToday: true,
      filterLoading: false,
      rightmoveUrl: "https://www.google.com/embeded",
      indexSeletecd: undefined,
      filtersection: false,
      loading: false,
      page: 1,
      limit: 100,
      area: undefined,
      postcode: "",
      minprice: undefined,
      maxprice: undefined,
      minyield: undefined,
      maxyield: undefined,
      propertyTypes: [
        "Detached",
        "Semi-Detached",
        "Terraced",
        "Flat",
        "Bungalow",
        "Land",
      ],
      propertyType: ["Detached", "Semi-Detached", "Terraced", "Bungalow"],
      soldStcs: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      loadingEmailInquiry: false,
      propertyStatus: undefined,
      postcodes: [],
      postcodesDefaults: [
        "BB",
        "BD",
        "BL",
        "CA",
        "CH",
        "CW",
        "DL",
        "DN",
        "HD",
        "HG",
        "HU",
        "HX",
        "L",
        "LA",
        "LS",
        "M",
        "NE",
        "OL",
        "PR",
        "S",
        "SK",
        "SR",
        "TS",
        "WA",
        "WF",
        "WN",
        "YO",
      ],
      wishlist: false,
      isListing: true,
      isListingLives: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],

      dayAdded: [
        {
          title: "All",
          day: 0,
        },
        {
          title: "Last 24 Hours",
          day: 1,
        },
        {
          title: "Last 3 Days",
          day: 3,
        },
        {
          title: "Last 7 Days",
          day: 7,
        },
        {
          title: "Last 14 Days",
          day: 14,
        },
      ],
      dayAddedSelected: 0,
      maxbed: undefined,
      minbed: undefined,
      filterCondition: {},
      jsondata: [],
      prefixpostcode: undefined,
      prepostcode: undefined,
      minRentYield: undefined,
      minLhaYield: undefined,
      returnMarketSort: undefined,
      averageRentSort: undefined,
      averagePriceValutionSort: undefined,
      distcountMarketSort: undefined,
      yieldSort: -1,
      searches: [],
      search: undefined,
      searchName: "",
      popSearch: false,
      propertyUpdatePop: true,
      yieldRequired: 0,
      commentData: {
        propertyId: "",
        status: false,
      },
      selectedProperty: 0,
      loadMore: false,
      properties: [],
      propertyLoading: true,
      loadingPagination: true,
      paginate: 1,
      pagination: {},
      listedDate: [],
      sendEmailLoading: false,
    };
  },
  watch: {
    async changePostCode(v) {
      this.loading = true;
      await this.getPostCodes({ postcode: v });
      this.loading = false;
    },
    async changePreCode(v) {
      this.loading = true;
      await this.getPrePostCodes({ postcode: v });
      this.loading = false;
    },
    async changePrefixCode(v) {
      this.loading = true;
      await this.getPrefixPostCodes({ postcode: v });
      this.loading = false;
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions([
      "fetchPaginationPropertyDetails",
      "getRightMovesSalesDetail",
      "actionFuction",
      "getPostCodes",
      "getPrePostCodes",
      "getPrefixPostCodes",
      "getFilterDownload",
      "getRentProperties",
      "getRegion",
      "getPropertyInquiry",
      "addComment",
      "listOfComments",
      "setSearch",
      "getSearch",
      "getSearches",
      "getDashboradControl",
      "propertiesCount",
      "propertyVerfication",
      "propertyReport",
      "getPrePostCodes",
      "getRentProperties",
      "postWishlist",
      "getRentDataEmail",
    ]),
    resetPostcode() {
      this.postcodesDefaults = [
        "BB",
        "BD",
        "BL",
        "CA",
        "CH",
        "CW",
        "DL",
        "DN",
        "HD",
        "HG",
        "HU",
        "HX",
        "L",
        "LA",
        "LS",
        "M",
        "NE",
        "OL",
        "PR",
        "S",
        "SK",
        "SR",
        "TS",
        "WA",
        "WF",
        "WN",
        "YO",
      ];
    },
    async wishlistAct(ele) {
      ele.wishlist = !ele.wishlist;
      await this.postWishlist({
        propertyId: ele.propertyId,
        propertyFor: "rent",
      });
    },
    async creatSearch() {
      this.filterData();
      await this.setSearch({
        ...this.filterCondition,
        searchName: this.searchName,
        searchSaveFor: "rent",
      });
      await this.getSearches();
      this.searches = this.allSearches.results;
      this.popSearch = false;
    },
    clearPostcode() {
      this.postcodesDefaults = [];
    },
    makeItActive(index) {
      this.indexSeletecd = index;
    },
    changeSortYield(data) {
      this.yieldSort = data;
      this.filterData();
    },

    toggleFilter() {
      this.filtersection = !this.filtersection;
    },
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    async nextPage(nt) {
      this.filterLoading = true;
      this.jsondata = [];
      if (this.filterCondition) {
        if (this.allPropertiesCount.pagination.next != undefined) {
          await this.getRentProperties({
            ...this.filterCondition,
            limit: this.limit,
            page: nt,
          });
        }
      } else {
        if (this.allPropertiesCount.pagination.next != undefined) {
          await this.getRentProperties({
            limit: this.limit,
            page: nt,
          });
        }
      }
      this.properties = this.allRentProperties.results;
      this.jsondata = [];
      this.filterLoading = false;
    },
    async prevPage(pt) {
      this.filterLoading = true;
      this.jsondata = [];
      if (this.filterCondition) {
        if (this.pagination.previous != undefined) {
          await this.getRentProperties({
            ...this.filterCondition,
            limit: this.limit,
            page: pt,
          });
        }
      } else {
        if (this.pagination.previous != undefined) {
          await this.getRentProperties({
            limit: this.limit,
            page: pt,
          });
        }
      }
      this.properties = this.allRentProperties.results;
      this.jsondata = [];
      this.filterLoading = false;
    },

    async sendDataToEmail() {
      this.sendEmailLoading = true;
      await this.getRentDataEmail(this.filterCondition);
      this.sendEmailLoading = false;
    },

    async handleScroll() {
      let sticky = this.filtersection ? 900 : 300;
      if (window.pageYOffset > sticky) {
        this.stickWishlist = true;
      } else {
        this.stickWishlist = false;
      }
    },

    async filterData() {
      this.filterLoading = true;
      this.loadingToday = true;
      this.loadingPagination = true;
      this.jsondata = [];
      this.filterCondition.prefixpostcode = this.prefixpostcode;

      this.filterCondition.isListingLive = this.isListing;

      if (this.maxbed != undefined) {
        this.filterCondition.maxbed = this.maxbed;
      }

      if (this.propertyStatus != undefined) {
        this.filterCondition.propertyStatus = this.propertyStatus;
      }

      this.filterCondition.wishlist = this.wishlist;

      if (this.area != undefined) {
        this.filterCondition.area = this.area;
      }
      if (this.minbed != undefined) {
        this.filterCondition.minbed = this.minbed;
      }

      if (this.minLhaYield != undefined) {
        this.filterCondition.minLhaYield = this.minLhaYield;
      }
      if (this.minRentYield != undefined) {
        this.filterCondition.minRentYield = this.minRentYield;
      }
      if (this.maxprice != undefined) {
        this.filterCondition.maxprice = this.maxprice;
      }

      if (this.minprice != undefined) {
        this.filterCondition.minprice = this.minprice;
      }

      if (this.propertyType != undefined) {
        this.filterCondition.houseSubtype = this.propertyType;
      }
      if (this.postcodesDefaults.length > 0) {
        this.filterCondition.postcodes = this.postcodesDefaults;
      }

      if (this.listedDate.length > 0) {
        this.filterCondition.listedDate = this.listedDate;
      }

      this.filterCondition.isParkHome = this.isParkHome;
      this.filterCondition.radius = null;
      this.filterCondition.limit = this.limit;
      this.filterCondition.page = 1;

      this.filterCondition.yieldSort = this.yieldSort;

      await this.getRentProperties(this.filterCondition);
      this.filterLoading = false;
      this.properties = this.allRentProperties.results;
      this.pagination = this.allRentProperties.pagination;
    },

    async sendEmailAct() {
      await this.getFilterDownload(this.filterCondition);
    },

    searchPopup() {
      this.popSearch = true;
    },
    searchClosePopup() {
      this.popSearch = false;
    },

    getFormatedDate(data) {
      return moment(new Date(data)).format("MM/DD/YYYY");
    },
    getDateTime(timeGoing) {
      // console.log(timeGoing);
      let timeGo;
      if (timeGoing != "Added today") {
        let t = timeGoing.split("/");
        timeGo = this.getFormatedDate(`${t[1]}/${t[0]}/${t[2]}`);
      } else {
        timeGo = this.getFormatedDate(timeGoing);
      }

      // console.log(timeGo);

      let end = moment(moment_timezone(new Date(timeGo), "Europe/London"));
      let now = moment(moment_timezone(new Date(), "Europe/London"));

      var delta = Math.abs(end - now) / 1000;
      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = delta % 60;

      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
      return days;
    },

    dateFixer() {
      const now = new Date();
      let datesBox = [];
      if (this.dayAddedSelected == 1) {
        datesBox.push(
          moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
          ).format("DD/MM/YYYY")
        );
        this.listedDate = datesBox;
      } else if (this.dayAddedSelected == 3) {
        for (let i = 1; i <= 3; i++) {
          datesBox.push(
            moment(
              new Date(now.getFullYear(), now.getMonth(), now.getDate() - i)
            ).format("DD/MM/YYYY")
          );
        }
        this.listedDate = datesBox;
      } else if (this.dayAddedSelected == 7) {
        for (let i = 1; i <= 7; i++) {
          datesBox.push(
            moment(
              new Date(now.getFullYear(), now.getMonth(), now.getDate() - i)
            ).format("DD/MM/YYYY")
          );
        }
        this.listedDate = datesBox;
      } else if (this.dayAddedSelected == 14) {
        for (let i = 1; i <= 14; i++) {
          datesBox.push(
            moment(
              new Date(now.getFullYear(), now.getMonth(), now.getDate() - i)
            ).format("DD/MM/YYYY")
          );
        }
        this.listedDate = datesBox;
      } else {
        this.listedDate = [];
      }
    },
    async getSeachSingle() {
      await this.getSearch(this.search);
      if (this.allSearch.results.minprice != undefined) {
        this.minprice = this.allSearch.results.minprice;
      }
      if (this.allSearch.results.maxprice != undefined) {
        this.maxprice = this.allSearch.results.maxprice;
      }
      if (this.allSearch.results.minbed != undefined) {
        this.minbed = this.allSearch.results.minbed;
      }
      if (this.allSearch.results.maxbed != undefined) {
        this.maxbed = this.allSearch.results.maxbed;
      }
      if (this.allSearch.results.minRentYield != undefined) {
        this.minRentYield = this.allSearch.results.minRentYield;
      }
      if (this.allSearch.results.minLhaYield != undefined) {
        this.minLhaYield = this.allSearch.results.minLhaYield;
      }
      if (this.allSearch.results.auctionProperty != undefined) {
        this.auctionProperty = this.allSearch.results.auctionProperty;
      }
      if (this.allSearch.results.soldstc != undefined) {
        this.soldstc = this.allSearch.results.soldstc;
      }
      if (this.allSearch.results.newbuild != undefined) {
        this.newbuild = this.allSearch.results.newbuild;
      }
      if (this.allSearch.results.sharedOwnership != undefined) {
        this.sharedOwnership = this.allSearch.results.maxbed;
      }
      if (this.allSearch.results.retirementHome != undefined) {
        this.retirementHome = this.allSearch.results.retirementHome;
      }
      if (this.allSearch.results.needRefurbishment != undefined) {
        this.needRefurbishment = this.allSearch.results.needRefurbishment;
      }
      if (this.allSearch.results.isParkHome != undefined) {
        this.isParkHome = this.allSearch.results.isParkHome;
      }
      if (this.allSearch.results.sharedOwnership != undefined) {
        this.sharedOwnership = this.allSearch.results.sharedOwnership;
      }
      if (this.allSearch.results.website != undefined) {
        this.website = this.allSearch.results.website;
      }
      if (this.allSearch.results.houseSubtype != undefined) {
        this.houseSubtype = this.allSearch.results.houseSubtype;
      }
      if (this.allSearch.results.region != undefined) {
        this.region = this.allSearch.results.region;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  async created() {
    await this.getPrePostCodes();
    this.postcodes = this.allPostCodePrefix.results;
    await this.filterData();
    this.propertyLoading = false;
    await this.getSearches("rent");
    this.searches = this.allSearches.results;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dateHighlight {
  background: yellow;
}
.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}
h4 {
  margin-bottom: 0;
}
.v-icon {
  font-size: 16px;
  cursor: pointer;
}
.downloadbtn {
  margin-left: 20px;
}
.fixBoxTop {
  position: fixed;
  top: 20px;
  z-index: 99999;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}
.heightZero {
  height: 0;
}
.actionbtn {
  margin-bottom: 20px;
}
.activeTab {
  background: rgb(195, 195, 196);
  color: rgb(20, 20, 20);
  a {
    color: rgb(5, 5, 5);
  }
}
.mr-btm {
  margin-bottom: 30px;
  margin-top: -40px;
}
a {
  text-decoration: none;
}
.mr-left {
  margin-left: 10px;
}
.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;
  li {
    list-style: none;
    padding: 8px 20px;
  }
}
.wishlistBtn {
  position: fixed;
  top: 0px;
  right: 370px;
  z-index: 999;
  transition: 0.3s all ease-in-out;
}

.dropBox {
  list-style: none;
  padding-left: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0 5px 10px #efefef;
  li {
    padding: 10px 25px;
    border-bottom: 1px solid #dfdddd;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }
  }
}
.relRow {
  position: relative;
  .dropBox {
    position: absolute;
    width: 150px;
    display: none;
  }
  &:hover {
    .dropBox {
      display: block;
    }
  }
}
.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }
  .formBox {
    background: #fff;
    border-radius: 10px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    font-size: 14px;
    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
.rightmovePopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    width: 500px;
    height: 500px;
  }
}
.flexBox-space {
  display: flex;
  justify-content: space-between;
}
.iconSection {
  .v-icon {
    font-size: 30px;
  }
}

.dash {
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.d-flex {
  .justify-space-between {
    span {
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
.flexend {
  justify-content: flex-end;
  .v-btn {
    margin-left: 10px;
  }
}

.ulTop {
  list-style: none;
  padding-left: 0;
  width: 100%;
  padding-top: 10px;
  h5 {
    font-size: 16px;
    margin-bottom: 5px;
    @media only screen and (max-width: 1369px) {
      font-size: 13px;
    }
  }
  margin-bottom: 20px;
  li {
    box-shadow: 0 3px 5px #efefef;
    padding: 20px 40px;
    margin-right: 15px;
    width: 100%;
    span {
      font-size: 14px;
      display: block;
      padding: 5px 0;
      @media only screen and (max-width: 1369px) {
        font-size: 12px;
      }
    }
  }
}
.btnActReset {
  width: 90px;
}

.resetBtn {
  color: blue;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}
.filterBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  min-height: 60px;
  width: 50px;
  bottom: 50px;
}
.formInside {
  margin: 0;
  z-index: 999999;
  width: 150px !important;
  right: 200px;
  top: 15px;
  position: fixed;
}

.leftSec {
  margin: 0;
  z-index: 999999;
  position: fixed;
  top: 15px !important;
  width: 150px !important;
  &:nth-of-type(1) {
    left: 280px;
  }
  &:nth-of-type(2) {
    left: 420px;
  }
}
.favourite {
  color: red;
  width: 50px;
  height: 50px;
}
.favouriteO {
  width: 50px;
  height: 50px;
}
</style>
